<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Form from "./components/form.vue";
import { userMethods } from "@/state/helpers";
export default {
  components: {
    Layout,
    PageHeader,
    Form,
  },
  data() {
    return {
      processing: false,
      title: "Manager",
      items: [
        {
          text: "Manager",
        },
        {
          text: "Create Manager",
          active: true,
        },
      ],
    };
  },

  methods: {
    ...userMethods,

    submitUser() {
      let user = this.$store.state.userModule.user;
      this.$refs.createManager.checkValidate();
      if(this.$refs.createManager.phoneError == false)
      {
        return;
      }
      if (this.$refs.createManager.validateErorr()) {
        return;
      } else {
        let self = this;
        if (self.processing) {
          return;
        }
        self.processing = true;

        self.$axios
          .post("/employee/manager/store", {
            name: user.name,
            email: user.email,
            phone: user.phone,
            avatar: user.avatar,
            password: user.password,
            // location_id:user.location_id,
            location_id: user.locations.map((item) => {
              return item.id;
            }),
            work_area_role_id: user.work_area_role.map((item) => {
              return item.id;
            }),
            work_area: user.work_area.map((item) => {
              return item.name;
            }),
          })
          .then((response) => {
            let data = response.data;

            self.triggerSwal(data.message, "success");
            self.resetState();
            self.$router.push("/managers-listing");
          })
          .catch((error) => {
            self.handleErrorResponse(error.response, "error");
          })
          .finally(() => {
            self.processing = false;
          });
      }
    },
  },
  mounted() {
    this.resetState();
  },

  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="card p-4 radius-20">
      <Form ref="createManager"></Form>
      <div class="col-md-12">
        <SubmitButton
          text="Create Profile"
          @clicked="submitUser"
          :processing="processing"
        ></SubmitButton>
      </div>
    </div>
  </Layout>
</template>
